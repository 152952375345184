/**
 * TODO:
 *   1. this is not a pure component, but it's not like a container either.
 *      what a best way to categorize this?
 */
import { useUnit } from 'effector-react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { hasRoles } from 'apps/admin/permissions/RouteManager';
import { authStore } from 'apps/admin/state/auth';
import { KEYCLOAK_ENABLED } from 'config/env';

import { RoleNameValue } from 'types/api/iam/Iam';

export interface AuthenticatedRouteProps extends Omit<RouteProps, 'children'> {
  breadcrumb?: React.ComponentType | string | null;
  roles?: RoleNameValue[];
  children?: React.ReactNode;
}

export default function AuthenticatedRoute({ children, roles, ...props }: AuthenticatedRouteProps) {
  const { authenticated } = useUnit(authStore);

  // DO NOT redirect to /login when authentication status is undetermined
  if (authenticated === null || (roles && !hasRoles(roles))) {
    // or redirect to login??
    return null;
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        KEYCLOAK_ENABLED || authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
