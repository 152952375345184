import { hasRoles } from 'apps/admin/permissions/RouteManager';
import {
  CreateUserResponse,
  GetResourceRoleFromUUID,
  GetUserInfoResponse,
  GetUserListResponse,
  GetUserLocationsResponse,
  GetUserResourceGroupsResponse,
} from 'apps/admin/services/iam/UserIamService.types';
import { OperatorService } from 'apps/admin/services/operator/OperatorService';
import { KeycloakService } from 'services/KeycloakService';
import { apiFetch } from 'utils/http';

import {
  ENTERPRISE_OPERATOR_SUFFIX,
  IamResource,
  IamRole,
  IamAttributes,
  IamUser,
  LOCATION_ACCESS_GROUP_SUFFIX,
  LocationAccessType,
  OperatingGroupType,
  OperatingGroupTypeValue,
  OperatingGroupView,
  OPERATOR_GROUP_SUFFIX,
  ResourceType,
  RoleName,
  RoleType,
  RoleTypeValue,
  ENTERPRISE_USER_GROUP_IDENTIFIER,
} from 'types/api/iam/Iam';
import { Operator } from 'types/api/iam/IamOperator';

export const IamUserService = {
  // roles
  getUserResourceGroups: async ({
    userId,
    showInherited = false,
  }: {
    userId: string;
    showInherited?: boolean;
  }): Promise<GetUserResourceGroupsResponse> => {
    const res = await apiFetch(`/v2/user/iam/realm/users/${userId}/roles`, {
      statusCodes: true,
      params: { showInherited },
    });

    const normalizedRoles = res.data.roles.map((role: IamRole) => normalizeIamRole(role));

    if (res.success) {
      return {
        roles: filterServerRoles(normalizedRoles),
        resourceGroups: filterOperatingGroupResourceGroupRoles(normalizedRoles),
        enterpriseOperators: filterEnterpriseOperatorResourceGroupRoles(normalizedRoles),
        operatorGroups: filterOperatorGroupResourceGroupRoles(normalizedRoles),
        userGroups: filterUserGroupRoles(normalizedRoles),
        locationBasedUserGroups: filterLocationBasedUserGroupRoles(normalizedRoles),
      };
    }
    return {
      roles: [],
      resourceGroups: [],
      enterpriseOperators: [],
      operatorGroups: [],
      userGroups: [],
      locationBasedUserGroups: [],
    };
  },
  getAssignedRoles: async () => {
    const res = await apiFetch('/v2/user/iam/realm/user/roles', {
      statusCodes: true,
      params: { showInherited: false },
    });

    const normalizedRoles = res.data.roles.map((role: IamRole) => normalizeIamRole(role));

    if (res.success) {
      return {
        roles: filterServerRoles(normalizedRoles),
        resourceGroups: filterOperatingGroupResourceGroupRoles(normalizedRoles),
        enterpriseOperators: filterEnterpriseOperatorResourceGroupRoles(normalizedRoles),
        operatorGroups: filterOperatorGroupResourceGroupRoles(normalizedRoles),
        userGroups: filterUserGroupRoles(normalizedRoles),
        locationBasedUserGroups: filterLocationBasedUserGroupRoles(normalizedRoles),
      };
    }
    return {
      roles: [],
      resourceGroups: [],
      enterpriseOperators: [],
      operatorGroups: [],
      userGroups: [],
      locationBasedUserGroups: [],
    };
  },

  /**
   * Logged-in user's assignable roles
   */
  getInheritedRoles: async (): Promise<GetUserResourceGroupsResponse> => {
    const tokenParsed = KeycloakService.getTokenParsed();
    if (tokenParsed?.sub) {
      const userId = tokenParsed?.sub;
      return IamUserService.getUserResourceGroups({ userId, showInherited: true });
    }
    return {
      roles: [],
      resourceGroups: [],
      enterpriseOperators: [],
      operatorGroups: [],
      userGroups: [],
      locationBasedUserGroups: [],
    };
  },

  /**
   * User information
   * @param userId
   * @param roleId - undefined if you're searching for logged in user
   */
  getUser: async ({ userId }: { userId: string }): Promise<GetUserInfoResponse> => {
    const res = await apiFetch(`/v2/user/iam/realm/users/${userId}`, { statusCodes: true });
    if (res.success) {
      return res.data.user;
    }
    return null;
  },

  getLoggedInUser: async (): Promise<GetUserInfoResponse> => {
    const tokenParsed = KeycloakService.getTokenParsed();
    if (tokenParsed?.sub) {
      const userId = tokenParsed?.sub;
      return IamUserService.getUser({ userId });
    }
    throw new Error('No user found');
  },

  getUserList: async ({
    query,
    operator,
    page,
    pageSize,
  }: {
    query?: string;
    operator?: string;
    page: number;
    pageSize: number;
  }): Promise<GetUserListResponse> => {
    const tokenParsed = KeycloakService.getTokenParsed();
    if (tokenParsed?.sub) {
      const userId = tokenParsed?.sub;
      const isSuperAdmin = hasRoles([RoleName.SUPER_ADMIN]);
      if (isSuperAdmin && !operator) {
        return [];
      }
      const res = await apiFetch(
        isSuperAdmin
          ? `/v2/admin/user/iam/realm/users/${userId}/users`
          : `/v2/user/iam/realm/users/${userId}/users`,
        {
          statusCodes: true,
          params: {
            query,
            page,
            pageSize,
            operator: isSuperAdmin ? operator : undefined,
          },
        },
      );
      if (res.success) {
        return res.data.users;
      }
    }
    return [];
  },

  createUser: async (body: {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    operatorName?: string | null;
    operatorUserGroupId?: string | null;
    operatorId?: string | null;
    roles: { roleId: string; roleType: string }[]; // todo role type
    emailRedirectUrl: string;
    clientId: string;
  }): Promise<CreateUserResponse> => {
    const { operatorId, operatorUserGroupId, operatorName, ...restBody } = body;
    const isSuperAdmin = hasRoles([RoleName.SUPER_ADMIN]);
    const res = await apiFetch(
      isSuperAdmin ? '/v2/admin/iam/realm/subject' : '/v2/iam/realm/subject',
      {
        method: 'POST',
        statusCodes: true,
        body: {
          subjects: [
            {
              user: {
                ...restBody,
                operatorId: isSuperAdmin ? operatorId : undefined,
                attributes: {
                  attributes: {
                    operatorName: { value: [operatorName] },
                    operatorUserGroupId: { value: [operatorUserGroupId] },
                  },
                },
              },
            },
          ],
          requestActions: {
            subjectId: '',
            actions: ['UPDATE_PASSWORD'],
            emailExpiryTimeSeconds: 172800, // 48 hours
            redirectUri: body.emailRedirectUrl,
            clientId: body.clientId,
          },
        },
      },
    );
    if (res.success) {
      return res.data.subjectIds[0];
    }
    throw new Error('Failed to create user');
  },

  deleteUser: async ({ userId }: { userId: string }): Promise<boolean> => {
    const res = await apiFetch(`/v2/iam/realm/subject/${userId}`, {
      method: 'DELETE',
      statusCodes: true,
    });
    if (res.success) {
      return true;
    }
    throw new Error('Failed to create user');
  },

  updateSubject: async ({
    userId,
    firstname,
    lastname,
    operator,
  }: {
    userId: string;
    firstname?: string;
    lastname?: string;
    operator?: {
      name: string;
      resourceId: string;
      userRoleId: string;
    };
  }): Promise<CreateUserResponse> => {
    const isSuperAdmin = hasRoles([RoleName.SUPER_ADMIN]);
    const attributes = { attributes: {} };
    if (operator) {
      attributes.attributes = {
        operatorName: { value: [operator.name] },
        operatorUserGroupId: { value: [operator.userRoleId] },
      };
    }
    const operatorId: string = isSuperAdmin ? (operator?.resourceId ?? '') : '';

    const res = await apiFetch(
      isSuperAdmin ? `/v2/admin/iam/realm/subjects/${userId}` : `/v2/iam/realm/subjects/${userId}`,
      {
        method: 'POST',
        statusCodes: true,
        body: {
          subjectId: userId,
          subject: {
            user: {
              firstname,
              lastname,
              operatorId,
              attributes,
            },
          },
        },
      },
    );
    if (res.success) {
      return res.data.user;
    }
    throw new Error('Failed to create user');
  },

  getResourceRoleLocations: async ({
    resourceGroupId,
  }: {
    resourceGroupId: string;
  }): Promise<GetUserLocationsResponse> => {
    const res = await apiFetch('/v2/iam/resource/resources', {
      method: 'POST',
      statusCodes: true,
      body: {
        resourceTarget: { role: { resourceRoleId: resourceGroupId } },
      },
    });

    if (res.success) {
      return res.data;
    }

    throw new Error('Failed to fetch resources');
  },

  getUserLocations: async ({
    userId,
    targetUserRoleId,
  }: {
    userId: string;
    targetUserRoleId: string;
  }): Promise<GetUserLocationsResponse> => {
    const res = await apiFetch('/v2/iam/resource/resources', {
      method: 'POST',
      statusCodes: true,
      body: {
        resourceTarget: { subject: { subjectId: userId } },
        requestSubject: {
          requestSubjectId: targetUserRoleId,
        },
      },
    });

    if (res.success) {
      return res.data;
    }

    throw new Error('Failed to fetch available resources');
  },

  getRoleDetails: async ({
    roleId,
    roleType,
  }: {
    roleId: string;
    roleType: RoleTypeValue;
  }): Promise<IamRole> => {
    const res = await apiFetch(`/v2/user/iam/realm/roles/${roleId}`, {
      method: 'GET',
      params: { roleType },
      statusCodes: true,
    });
    if (res.success) {
      return normalizeIamRole(res.data.role);
    }
    throw new Error('Failed to get composite role');
  },

  getCompositeRoles: async ({
    parentRoleId,
    roleType,
  }: {
    parentRoleId: string;
    roleType: RoleTypeValue;
  }): Promise<IamRole[]> => {
    const res = await apiFetch(`/v2/user/iam/realm/roles/${parentRoleId}/roles`, {
      method: 'GET',
      params: { roleType },
      statusCodes: true,
    });
    if (res.success) {
      return res.data.roles.map((role: IamRole) => normalizeIamRole(role));
    }
    throw new Error('Failed to get composite role');
  },

  addRoleToCompositeRole: async ({
    parentRoleId,
    roleIds,
  }: {
    parentRoleId: string;
    roleIds: string[];
  }) => {
    const res = await apiFetch(`/v2/user/iam/realm/roles/${parentRoleId}/roles`, {
      body: {
        compositeRoleId: parentRoleId,
        roleIds,
        roleModifyType: 'ADD_ROLE',
      },
      method: 'POST',
      statusCodes: true,
    });
    if (res.success) {
      return true;
    }
    throw new Error('Failed to add roles');
  },

  removeRoleFromCompositeRole: async ({
    parentRoleId,
    roleIds,
  }: {
    parentRoleId: string;
    roleIds: string[];
  }) => {
    const res = await apiFetch(`/v2/user/iam/realm/roles/${parentRoleId}/roles`, {
      body: {
        compositeRoleId: parentRoleId,
        roleIds,
        roleModifyType: 'REMOVE_ROLE',
      },
      method: 'POST',
      statusCodes: true,
    });
    if (res.success) {
      return true;
    }
    throw new Error('Failed to add roles');
  },

  createResourceRole: async ({
    name,
    operatorId,
    operatorUserGroupId,
    operatorName,
    operatingGroupType,
    roleType,
  }: {
    name: string;
    operatorId?: string;
    operatorUserGroupId?: string;
    operatorName?: string;
    operatingGroupType: OperatingGroupTypeValue;
    roleType: RoleTypeValue;
  }): Promise<IamRole> => {
    const isSuperAdmin = hasRoles([RoleName.SUPER_ADMIN]);
    const res = await apiFetch(isSuperAdmin ? '/v2/admin/iam/realm/role' : '/v2/iam/realm/role', {
      body: {
        roles: [
          {
            name,
            description: name,
            roleType,
            operatorId: operatorId || undefined,
            attributes: {
              attributes: {
                operatorId: operatorId ? { value: [operatorId] } : undefined,
                operatorUserGroupId: operatorUserGroupId
                  ? { value: [operatorUserGroupId] }
                  : undefined,
                operatorName: operatorName ? { value: [operatorName] } : undefined,
                operatingGroupType: { value: [operatingGroupType] },
              },
            },
          },
        ],
      },
      method: 'POST',
      statusCodes: true,
    });

    if (res.success) {
      return res.data.roles[0];
    }
    throw new Error('Failed to create resource group');
  },

  createOperator: async ({
    name,
    platformGroupId,
  }: {
    name: string;
    platformGroupId: string;
  }): Promise<IamRole> => {
    const operatorRes = await OperatorService.createOperator({ name });
    if (!operatorRes.success) {
      throw new Error('Could not create operator');
    }
    const operatorUuid = operatorRes.data.uuid;

    // enterprise operator resource group is created in the backend, so we look up the role
    // and then assign it to the operator user group
    const { enterpriseOperators } = await IamUserService.getInheritedRoles();
    const enterpriseOperatorRole = enterpriseOperators.find(
      (role) => name === role.name.replace(` ${ENTERPRISE_OPERATOR_SUFFIX}`, ''),
    );
    if (!enterpriseOperatorRole) {
      throw new Error('The enterprise operator was not created');
    }

    // Create operator iam resource
    const createOperatorSuccess = await IamUserService.createResource({
      uuid: operatorUuid,
      name,
      resourceType: 'OPERATOR_RESOURCE',
    });
    if (!createOperatorSuccess) {
      throw new Error('Could not create operator resource');
    }

    // Create operator user group
    const operatorUserGroup = await IamUserService.createResourceRole({
      name,
      operatorId: operatorUuid,
      operatorName: name,
      operatingGroupType: OperatingGroupType.OPERATOR,
      roleType: RoleType.USER_CLIENT_ROLE,
    });

    // Assign operator to platform
    await IamUserService.addRoleToCompositeRole({
      parentRoleId: platformGroupId,
      roleIds: [operatorUserGroup.roleId],
    });

    // Create operator group
    const operatorGroupRole = await IamUserService.createResourceRole({
      name: `${name} ${OPERATOR_GROUP_SUFFIX}`,
      operatorId: operatorUuid,
      operatorUserGroupId: operatorUserGroup.roleId,
      operatorName: `${name} ${OPERATOR_GROUP_SUFFIX}`,
      operatingGroupType: OperatingGroupType.OPERATOR_GROUP,
      roleType: RoleType.RESOURCE_CLIENT_ROLE,
    });

    // Assign enterprise operator and operator group to operator
    await IamUserService.addRoleToCompositeRole({
      parentRoleId: operatorUserGroup.roleId,
      roleIds: [enterpriseOperatorRole.roleId, operatorGroupRole.roleId],
    });

    // Assign operator to operator group
    await IamUserService.assignResourceToResourceRole({
      resourceIds: [operatorUuid],
      resourceGroupId: operatorGroupRole.roleId,
    });

    return operatorUserGroup;
  },

  /**
   * Operating group is a user group and resource group
   * The resource group is assigned to the user group
   * @param name
   * @param operatorId
   * @param operatorName
   * @param enterpriseOperatorId
   * @param operatorGroupId
   */
  createOperatingGroup: async ({
    name,
    operatorId,
    operatorName,
    enterpriseOperatorId,
    operatorGroupId,
  }: {
    name: string;
    operatorId: string;
    operatorName: string;
    enterpriseOperatorId: string;
    operatorGroupId: string;
  }): Promise<IamRole> => {
    // Create user group and assign to the operator
    const userGroup = await IamUserService.createResourceRole({
      name,
      operatorId,
      operatorName,
      operatingGroupType: OperatingGroupType.OPERATING_GROUP,
      roleType: RoleType.USER_CLIENT_ROLE,
    });

    // Create the resource group
    const resourcePromise = IamUserService.createResourceRole({
      name,
      operatorId,
      operatorName,
      operatingGroupType: OperatingGroupType.OPERATING_GROUP,
      roleType: RoleType.RESOURCE_CLIENT_ROLE,
    });

    // Create the location specific user group
    const locationAccessPromise = IamUserService.createResourceRole({
      name: `${name} ${LOCATION_ACCESS_GROUP_SUFFIX}`,
      operatorId,
      operatorName,
      operatingGroupType: OperatingGroupType.LOCATION_ACCESS,
      roleType: RoleType.USER_CLIENT_ROLE,
    });

    const [resourceGroup, locationAccessGroup] = await Promise.all([
      resourcePromise,
      locationAccessPromise,
    ]);

    // assign roles and enterprise operator to the user group
    const assignRolesToOperatingGroupPromise = IamUserService.addRoleToCompositeRole({
      parentRoleId: userGroup.roleId,
      roleIds: [
        resourceGroup.roleId,
        locationAccessGroup.roleId,
        enterpriseOperatorId,
        operatorGroupId,
      ],
    });

    // assign operating group to operator
    const assignToOperatorPromise = IamUserService.addRoleToCompositeRole({
      parentRoleId: operatorId,
      roleIds: [userGroup.roleId],
    });

    await Promise.all([assignRolesToOperatingGroupPromise, assignToOperatorPromise]);

    return userGroup;
  },

  /**
   * Create operating group
   */

  /**
   * Site/Location Resource
   * @param uuid site uuid
   * @param name site name
   * @param resourceType resource type
   */
  createResource: async ({
    uuid,
    name,
    resourceType,
  }: {
    uuid: string;
    name: string;
    resourceType: 'OPERATOR_RESOURCE';
  }) => {
    const res = await apiFetch('/v2/admin/iam/realm/resource', {
      method: 'PUT',
      body: {
        resources: [
          {
            resourceId: uuid,
            name,
            resourceType,
          },
        ],
      },
    });

    return res.success;
  },

  assignResourceToResourceRole: async ({
    resourceIds,
    resourceGroupId,
  }: {
    resourceIds: string[];
    resourceGroupId: string;
  }) => {
    const res = await apiFetch('/v2/iam/resource/resources/assign', {
      method: 'PUT',
      body: {
        resourceIds,
        resourceTarget: {
          role: {
            resourceRoleId: resourceGroupId,
          },
        },
      },
    });

    return res.success;
  },

  removeResourcesFromResourceRole: async ({
    resourceIds,
    resourceGroupId,
  }: {
    resourceIds: string[];
    resourceGroupId: string;
  }) => {
    const res = await apiFetch('/v2/iam/resource/resources/remove', {
      method: 'DELETE',
      body: {
        resourceIds,
        resourceTarget: {
          role: {
            resourceRoleId: resourceGroupId,
          },
        },
      },
    });

    return res.success;
  },

  getUsersInUserGroup: async ({ userGroupId }: { userGroupId: string }): Promise<IamUser[]> => {
    const res = await apiFetch(`/v2/user/iam/realm/roles/${userGroupId}/users`, {
      method: 'GET',
      params: { roleType: RoleType.USER_CLIENT_ROLE },
      statusCodes: true,
    });
    if (res.success) {
      return res.data.users;
    }

    throw new Error('Could not get users');
  },

  assignLocationsToUser: async ({
    locationResourceIds,
    userId,
  }: {
    locationResourceIds: string[];
    userId: string;
  }) => {
    const res = await apiFetch('/v2/iam/resource/resources/assign', {
      method: 'PUT',
      statusCodes: true,
      body: {
        resourceIds: locationResourceIds,
        resourceTarget: {
          subject: {
            subjectId: userId,
          },
        },
      },
    });

    return res.success;
  },

  getResourceRole: async ({ id }: { id: string }): Promise<GetResourceRoleFromUUID> => {
    const res = await apiFetch(`/v2/iam/resource/resource/${id}/roles`, {
      method: 'GET',
      statusCodes: true,
    });

    if (res.success) {
      return res.data;
    }
    throw new Error('Failed to get resource role');
  },

  removeLocationsFromUser: async ({
    locationResourceIds,
    userId,
  }: {
    locationResourceIds: string[];
    userId: string;
  }) => {
    const res = await apiFetch('/v2/iam/resource/resources/remove', {
      method: 'DELETE',
      body: {
        resourceIds: locationResourceIds,
        resourceTarget: {
          subject: {
            subjectId: userId,
          },
        },
      },
    });

    return res.success;
  },

  /**
   * Roles are roles, operatingGroups, and markets
   * @param roles
   * @param userId
   */
  addIamRolesToUser: async ({
    roles,
    userId,
  }: {
    roles: Pick<IamRole, 'roleId' | 'roleType'>[];
    userId: string;
  }) => {
    const res = await apiFetch(`/v2/user/iam/realm/users/${userId}/roles`, {
      method: 'POST',
      body: {
        userId,
        roles: roles.map(({ roleId, roleType }) => ({
          id: roleId,
          roleType,
        })),
        roleModifyType: 'ADD_ROLE',
      },
    });

    return res.success;
  },

  /**
   * Roles are roles, operatingGroups, and markets
   * @param roles
   * @param userId
   */
  removeIamRolesFromUser: async ({
    roles,
    userId,
  }: {
    roles: Pick<IamRole, 'roleId' | 'roleType'>[];
    userId: string;
  }) => {
    const res = await apiFetch(`/v2/user/iam/realm/users/${userId}/roles`, {
      method: 'POST',
      body: {
        userId,
        roles: roles.map(({ roleId, roleType }) => ({
          id: roleId,
          roleType,
        })),
        roleModifyType: 'REMOVE_ROLE',
      },
    });

    return res.success;
  },

  getUserOperatingGroups: async ({ userId }: { userId: string }) => {
    let role: IamRole | undefined;
    let operators: IamRole[] | undefined;
    let platformOperator: IamRole | undefined;

    const allowedOperators = await OperatorService.getOperators().then((operator) =>
      operator.map((op) => op.name),
    );
    const { operatingGroups, enterpriseUserGroups } = await IamUserService.getUserResourceGroups({
      userId,
    }).then(
      async ({ roles: r, userGroups: groupAccess, locationBasedUserGroups: locationAccess }) => {
        [role] = r;
        operators = groupAccess.filter(({ name }) => allowedOperators.includes(name));
        platformOperator = findPlatformUserGroup(groupAccess);

        const opGroups = await findOperatingGroups(groupAccess);
        const entUserGroups = filterEnterpriseUserGroupRoles(groupAccess);

        // fetch resource groups for operating groups
        const resourceGroupPromises: Promise<OperatingGroupView | undefined>[] = opGroups.map(
          async (userGroup) => {
            const compositeRoles = await IamUserService.getCompositeRoles({
              parentRoleId: userGroup.roleId,
              roleType: RoleType.RESOURCE_CLIENT_ROLE,
            });
            const resourceGroup = filterOperatingGroupResourceGroupRoles(compositeRoles)?.[0];
            if (resourceGroup) {
              const resources = await IamUserService.getResourceRoleLocations({
                resourceGroupId: resourceGroup.roleId,
              }).then(({ resourceRoleResources }) =>
                resourceRoleResources.flatMap((resource) => resource.resources),
              );
              return {
                role: userGroup,
                accessType: LocationAccessType.GROUP,
                resources,
              };
            }
            return undefined;
          },
        );

        // fetch user locations for location based groups
        const locationAccessPromises: Promise<OperatingGroupView>[] = locationAccess.map(
          async (userGroup) => {
            const resourceRes = await IamUserService.getUserLocations({
              userId,
              targetUserRoleId: userGroup.roleId,
            });
            return {
              role: userGroup,
              accessType: LocationAccessType.LOCATION,
              resources: resourceRes?.subjectResources?.resources || [],
            };
          },
        );

        const fetchRes = await Promise.all([...resourceGroupPromises, ...locationAccessPromises]);
        return {
          operatingGroups: fetchRes.filter((group) => !!group),
          enterpriseUserGroups: entUserGroups,
        };
      },
    );

    return {
      role,
      operatingGroups,
      enterpriseUserGroups,
      operators: operators || [],
      platformOperator,
    };
  },

  getOrCreateEnterpriseResourceRole: async ({ enterpriseId }: { enterpriseId: string }) =>
    apiFetch(`/v2/admin/enterprises/${enterpriseId}/user-role`, {
      method: 'PUT',
    }),
};

export function normalizeIamRole(payload: {
  roleId?: string;
  id?: string;
  name: string;
  roleType: RoleTypeValue;
  attributes?: { attributes?: IamAttributes };
}): IamRole {
  const roleId = payload.roleId || payload.id || '';
  const { name, roleType, attributes } = payload;

  return {
    id: roleId,
    roleId,
    name,
    roleType,
    attributes,
  };
}

export function filterServerRoles(roles: IamRole[]) {
  return roles.filter(({ roleType }) => roleType === RoleType.SERVER_CLIENT_ROLE);
}

export function filterOperatingGroupResourceGroupRoles(roles: IamRole[]) {
  return roles.filter(
    ({ roleType, name }) =>
      roleType === RoleType.RESOURCE_CLIENT_ROLE &&
      !name.endsWith(ENTERPRISE_OPERATOR_SUFFIX) &&
      !name.endsWith(OPERATOR_GROUP_SUFFIX),
  );
}

export function filterOperatorGroupResourceGroupRoles(roles: IamRole[]) {
  return roles.filter(
    ({ name, roleType }) =>
      roleType === RoleType.RESOURCE_CLIENT_ROLE && name.endsWith(OPERATOR_GROUP_SUFFIX),
  );
}

export function filterEnterpriseOperatorResourceGroupRoles(roles: IamRole[]) {
  return roles.filter(
    ({ name, roleType }) =>
      roleType === RoleType.RESOURCE_CLIENT_ROLE && name.endsWith(ENTERPRISE_OPERATOR_SUFFIX),
  );
}

export function filterEnterpriseUserGroupRoles(roles: IamRole[]) {
  return roles.filter(
    ({ name, roleType }) =>
      roleType === RoleType.USER_CLIENT_ROLE && name.includes(ENTERPRISE_USER_GROUP_IDENTIFIER),
  );
}

export function filterUserGroupRoles(roles: IamRole[]) {
  return roles.filter(
    ({ name, roleType }) =>
      roleType === RoleType.USER_CLIENT_ROLE && !name.endsWith(LOCATION_ACCESS_GROUP_SUFFIX),
  );
}

export function filterLocationBasedUserGroupRoles(roles: IamRole[]) {
  return roles.filter(
    ({ name, roleType }) =>
      roleType === RoleType.USER_CLIENT_ROLE && name.endsWith(LOCATION_ACCESS_GROUP_SUFFIX),
  );
}

export function filterSiteResource(resources: IamResource[]) {
  return resources.filter(({ resourceType }) => resourceType === ResourceType.SITE_RESOURCE);
}

export function filterOperatorUserGroups(roles: IamRole[]) {
  return roles.filter(
    ({ attributes }) =>
      attributes?.attributes?.operatingGroupType?.value.indexOf(OperatingGroupType.OPERATOR) === 0,
  );
}

/**
 * Until we can see the operating group attribute so a role, we'll need to filter by name
 */
export async function findOperatorUserGroups(roles: IamRole[]) {
  const operatorNames = await OperatorService.getOperators().then((operators) =>
    operators.map(({ name }) => name),
  );
  return roles.filter((role) => operatorNames.includes(role.name));
}

export async function findOperatingGroups(roles: IamRole[]) {
  const operatorNames = await OperatorService.getOperators().then((operators) =>
    operators.map(({ name }) => name),
  );
  return roles.filter(
    (role) =>
      !operatorNames.includes(role.name) &&
      role.name !== Operator.METROPOLIS_PLATFORM &&
      // todo: clean up filters pls
      !role.name.includes(ENTERPRISE_USER_GROUP_IDENTIFIER),
  );
}

export function findPlatformUserGroup(roles: IamRole[]) {
  return roles.find((role) => [Operator.METROPOLIS_PLATFORM].indexOf(role.name) > -1);
}
