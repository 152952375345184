import { KEYCLOAK_ENABLED } from 'config/env';
import { KeycloakService } from 'services/KeycloakService';

import { isDevelopment, isLocal, isLocalDev, isProd, isStaging } from 'constants/FeatureFlags';

import { KCClient, KCClientValue, RoleName, RoleNameValue } from 'types/api/iam/Iam';

export const PERMISSION_LOCATION = {
  VIEW_LOCATION: 'view_location',
  EDIT_LOCATION: 'edit_location',

  // Rates
  VIEW_LOCATION_RATE: 'view_location_rate',
  EDIT_LOCATION_RATE: 'edit_location_rate',

  // Fees
  VIEW_LOCATION_FEES: 'view_location_fees',
  EDIT_LOCATION_FEES: 'edit_location_fees',

  // Violation plan
  VIEW_LOCATION_VIOLATION_PLAN: 'view_location_violation_plan',
  EDIT_LOCATION_VIOLATION_PLAN: 'edit_location_violation_plan',

  // Pedestrian door
  VIEW_LOCATION_PEDESTRIAN_DOOR: 'view_location_pedestrian_door',
  EDIT_LOCATION_PEDESTRIAN_DOOR: 'edit_location_pedestrian_door',

  // Edge devices
  VIEW_LOCATION_EDGE_DEVICE: 'view_location_edge_device',
  EDIT_LOCATION_EDGE_DEVICE: 'edit_location_edge_device',

  // Lane
  VIEW_LOCATION_LANE: 'view_location_lane',
  EDIT_LOCATION_LANE: 'edit_location_lane',

  // Lane details
  VIEW_LOCATION_LANE_DETAILS: 'view_location_lane_details',
  EDIT_LOCATION_LANE_DETAILS: 'edit_location_lane_details',

  // Subscription plan
  VIEW_LOCATION_SUBSCRIPTION_PLAN: 'view_location_subscription_plan',
  EDIT_LOCATION_SUBSCRIPTION_PLAN: 'edit_location_subscription_plan',

  // Validations
  VIEW_LOCATION_VALIDATIONS: 'view_location_validations',
  // EDIT_LOCATION_VALIDATIONS: 'edit_location_subscription_plan',

  // Hours
  VIEW_LOCATION_HOURS: 'view_location_hours',
  EDIT_LOCATION_HOURS: 'edit_location_hours',
};

export const PERMISSION = {
  VIEW_ENTERPRISE: 'view_enterprise',
  EDIT_ENTERPRISE: 'edit_enterprise',

  VIEW_LOCATION_GROUP: 'view_location_group',
  EDIT_LOCATION_GROUP: 'edit_location_group',

  ...PERMISSION_LOCATION,

  VIEW_INTERNAL_USER: 'view_user',
  EDIT_USER: 'edit_user',

  VIEW_CUSTOMER: 'view_customer',
  EDIT_CUSTOMER: 'edit_customer',

  VIEW_VIOLATION: 'view_violation',
  EDIT_VIOLATION: 'edit_violation',

  VIEW_VIOLATION_EXPORT: 'view_violation_export',
  EDIT_VIOLATION_EXPORT: 'edit_violation_export',

  VIEW_DEV_TOOLS: 'view_dev_tools',

  // validations
  EDIT_VALIDATION_SERVICE_FEES: 'edit_validation_service_fees',
  VIEW_VALIDATION_AGREEMENT_OWNER: 'view_validation_agreement_owner',
};

export type PermissionValue = (typeof PERMISSION)[keyof typeof PERMISSION];

function getClient(): KCClientValue {
  if (isLocal) {
    return KCClient.LOCAL;
  }
  if (isLocalDev || isDevelopment) {
    return KCClient.DEV;
  }
  if (isStaging) {
    return KCClient.STAGING;
  }
  if (isProd) {
    return KCClient.PRODUCTION;
  }
  throw new Error('Environment not supported');
}

/**
 * We will eventually use this when we have more fine grained permissions.
 * Ideally we receive these permissions from the backend as well
 * @param requiredPermissions
 */
export function hasPermissions(requiredPermissions: PermissionValue[]) {
  if (!KEYCLOAK_ENABLED) {
    return true;
  }

  const parsedToken = KeycloakService.getTokenParsed();
  const resourceAccess = parsedToken?.resource_access || {};
  if (getClient() in resourceAccess) {
    const { roles } = resourceAccess[getClient()];
    const permissions = Array.from(
      new Set([
        ...roles.flatMap((access): PermissionValue[] => {
          if (access === RoleName.OPERATOR) {
            return [
              PERMISSION.VIEW_ENTERPRISE,
              PERMISSION.VIEW_LOCATION,
              PERMISSION.VIEW_LOCATION_GROUP,
              PERMISSION.VIEW_VIOLATION,
              PERMISSION.VIEW_INTERNAL_USER,
            ];
          }
          if (access === RoleName.OPERATIONAL_ADMIN) {
            return Object.values(PERMISSION);
          }
          return [];
        }),
      ]),
    );

    return requiredPermissions.every((permission) => permissions.includes(permission));
  }

  return false;
}

const INHERITED_ROLES = {
  [RoleName.SYSTEM_ADMIN]: [
    RoleName.SYSTEM_ADMIN,
    RoleName.SUPER_ADMIN,
    RoleName.OPERATIONAL_ADMIN,
  ],
  [RoleName.SUPER_ADMIN]: [RoleName.SUPER_ADMIN, RoleName.OPERATIONAL_ADMIN],
  [RoleName.OPERATIONAL_ADMIN]: [RoleName.OPERATIONAL_ADMIN, RoleName.LEADER, RoleName.MANAGER],
  [RoleName.LEADER]: [RoleName.LEADER],
  [RoleName.MANAGER]: [RoleName.MANAGER],
  [RoleName.ENFORCEMENT]: [RoleName.ENFORCEMENT],
  [RoleName.VALET]: [RoleName.VALET],
  [RoleName.OPERATOR]: [RoleName.OPERATOR],
  [RoleName.PORTAL_ASSOCIATE]: [RoleName.PORTAL_ASSOCIATE],
  // todo: ask BE to return inherited roles
};

export function hasRoles(allowedRoles: RoleNameValue[]) {
  if (!KEYCLOAK_ENABLED) {
    return true;
  }

  const parsedToken = KeycloakService.getTokenParsed();
  const resourceAccess = parsedToken?.resource_access || {};
  if (getClient() in resourceAccess) {
    const { roles } = resourceAccess[getClient()];
    const inheritedRoles = roles.flatMap((role) => INHERITED_ROLES[role]);
    return inheritedRoles.filter((r) => allowedRoles.indexOf(r) > -1).length > 0;
  }

  return false;
}
