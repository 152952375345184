import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import AdminApp from 'apps/admin';
import { KeycloakService } from 'services/KeycloakService';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';
import { setTokenName } from 'utils/http';
import initSentry from 'utils/initSentry';

import * as serviceWorker from './serviceWorker';

import 'antd/es/style/css';

if (!window.ResizeObserver) {
  /* eslint-disable global-require */
  window.ResizeObserver = require('resize-observer-polyfill');
  /* eslint-enable global-require */
}

setCurrentAppName('admin');
setTokenName('admin');
initDatadog('admin');
initSentry('admin');

const root = createRoot(document.getElementById('root')!);

KeycloakService.initKeycloak('login-required', () => {
  root.render(
    <Sentry.ErrorBoundary>
      <AdminApp />
    </Sentry.ErrorBoundary>,
  );
});

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
