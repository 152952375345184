import { lazy, Suspense, useEffect } from 'react';

import { useUnit } from 'effector-react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { BreadCrumbs } from 'apps/admin/components/BreadCrumbs';
// #region import routes
import { hasRoles } from 'apps/admin/permissions/RouteManager';
import CustomerRoutes from 'apps/admin/routes/CustomerRoutes';
import DevToolRoutes from 'apps/admin/routes/DevToolRoutes';
import EnterpriseRoutes from 'apps/admin/routes/EnterpriseRoutes';
import FeatureFlagRoutes from 'apps/admin/routes/FeatureFlagRoutes';
import LocationGroupRoutes from 'apps/admin/routes/LocationGroupRoutes';
import LocationRoutes from 'apps/admin/routes/LocationRoutes';
import ResourceGroupRoutes from 'apps/admin/routes/ResourceGroupRoutes';
import SiteSurveyRoutes from 'apps/admin/routes/SiteSurveyRoutes';
import UserRoutes from 'apps/admin/routes/UserRoutes';
import ViolationRoutes from 'apps/admin/routes/ViolationRoutes';
import VisitRoutes from 'apps/admin/routes/VisitRoutes';
// #endregion import routes
import { getCurrentUser, authStore, loginMicrosoft } from 'apps/admin/state/auth';
import AppAlert from 'components/AppAlert';
import { KEYCLOAK_ENABLED } from 'config/env';
import { useHeapIdentifyUser } from 'hooks/useHeapIdentifyUser';

import { showAlert, hideAlert } from 'state/alert';
import { fetchEnums, fetchLPStates } from 'state/enum';

import {
  AntdOverrides,
  StyledRootLayout,
  StyledMainLayout,
  StyledContent,
  AlertBox,
} from './AdminApp.styled';
import { trackAdminUser } from './utils/heapOnboardTracking';

import { RoleName } from 'types/api/iam/Iam';

// TODO: fix the location list button regression caused by lazy loading
// const BreadCrumbs = lazy(() => import('apps/admin/components/BreadCrumbs'));
const LoginContainer = lazy(() => import('apps/admin/containers/LoginContainer'));
const LoginRedirectPage = lazy(() => import('components/login/LoginRedirectPage'));
const Navigation = lazy(() => import('apps/admin/components/Navigation'));

const persistPathName = 'persist-path';

export default function Admin() {
  const history = useHistory();

  const [persistedPath, setPersistedPath] = useLocalStorage(persistPathName, '');

  const { pathname } = useLocation();
  const { authenticated, currentUser, status = {} } = useUnit(authStore);

  const hideNavbar = pathname.includes('/site-survey');

  useEffect(() => {
    getCurrentUser().then((response) => {
      const data = {
        email: response?.data?.user?.email,
        permissionGroups: response?.data?.user?.permissionGroups,
      };
      trackAdminUser(data);
    });
  }, []);

  useEffect(() => {
    if (KEYCLOAK_ENABLED || authenticated) {
      fetchEnums({ isAdmin: true });
      fetchLPStates();
    }
  }, [authenticated]);

  // Show error popup if Google login fails
  useEffect(() => {
    if (status.loginPending === false && status.loginSuccess === false) {
      showAlert({
        type: 'error',
        label: 'Authentication Failed',
      });
    } else {
      // hide popup if login successful
      hideAlert();
    }
  }, [status]);

  useEffect(() => {
    if (KEYCLOAK_ENABLED) {
      if (persistedPath?.length) {
        history.replace(persistedPath);
        setPersistedPath('');
      } else if (pathname === '/login' || pathname === '/login-redirect' || pathname === '/') {
        // TODO: build dashboard as the default page
        history.replace('/enterprise');
      }
    } else if (authenticated === false) {
      if (pathname.includes('site-survey')) setPersistedPath(pathname);
      if (pathname !== '/login-redirect' && pathname !== '/login') {
        history.replace('/login');
      }
    } else if (authenticated === true) {
      if (persistedPath?.length) {
        history.replace(persistedPath);
        setPersistedPath('');
      } else if (pathname === '/login' || pathname === '/login-redirect' || pathname === '/') {
        // TODO: build dashboard as the default page
        history.replace('/enterprise');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, history, pathname]);

  useHeapIdentifyUser({
    authenticated,
    user: currentUser,
  });

  return (
    <StyledRootLayout>
      <AlertBox>
        <AppAlert />
      </AlertBox>
      {(KEYCLOAK_ENABLED || authenticated) && (
        <Suspense fallback={null}>
          <Navigation />
        </Suspense>
      )}
      <StyledMainLayout hidenavbar={hideNavbar.toString()}>
        <StyledContent>
          {(KEYCLOAK_ENABLED || authenticated) && <BreadCrumbs />}
          {hasRoles([RoleName.MANAGER]) && (
            <>
              <EnterpriseRoutes />
              <DevToolRoutes />
              <LocationGroupRoutes />
              <LocationRoutes />
              <UserRoutes />
              <CustomerRoutes />
              <ViolationRoutes />
              <FeatureFlagRoutes />
              <VisitRoutes />
              <SiteSurveyRoutes />
              <ResourceGroupRoutes />
            </>
          )}
          <Switch>
            <Route exact path="/login">
              <Suspense fallback={null}>
                <LoginContainer />
              </Suspense>
            </Route>
            <Route exact path="/login-redirect">
              <Suspense fallback={null}>
                <LoginRedirectPage doLogin={loginMicrosoft} />
              </Suspense>
            </Route>
          </Switch>
        </StyledContent>
      </StyledMainLayout>
      {/* AntdOverrides is at the bottom so it has the most specificity */}
      <AntdOverrides />
    </StyledRootLayout>
  );
}
