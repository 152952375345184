import { APPROACH_SETTING_IGNORE } from 'apps/admin/constants/Camera';
import { type IdNameObject } from 'types';
import { apiFetch } from 'utils/http';

import { BigMacDevice, PedestrianDoorUpsertPayload } from 'types/api';

// TODO: replace `simulateApiFetch()` with `apiFetch()` when API is ready

const defaultCameraConfig = {
  topBound: 0,
  leftBound: 0,
  rightBound: 1,
  bottomBound: 1,
  minIntersection: 0,
  approachSettingType: APPROACH_SETTING_IGNORE,
};

export type AddLaneCameraPayload = {
  active: boolean;
  linkedSiteId?: number | null;
  directedLaneId: number;
  model?: string | null;
  notes: string | null;
  serial: string | null;
  siteEquipmentType?: IdNameObject;
  approachSettingType?: IdNameObject;
};

export type UpdateLaneCameraPayload = {
  id: number;
  enabled?: boolean;
  active?: boolean;
  linkedSiteId?: number | null;
  directedLaneId: number;
  make?: string;
  model?: string | null;
  notes?: string | null;
  serial: string | null;
  siteEquipmentType?: IdNameObject;
  approachSettingType?: IdNameObject;
};

export const LaneService = {
  /**
   * Get all lanes for a location
   */
  getLanes: ({ locationId }: { locationId: number }) => apiFetch(`/admin/site/${locationId}/lane`),

  /**
   * Create a new lane
   */
  createLane: ({
    locationId,
    body,
  }: {
    locationId: number;
    body: {
      name: string;
      direction: string;
    };
  }) =>
    apiFetch(`/admin/site/${locationId}/lane`, {
      method: 'POST',
      body,
    }),

  /**
   * Get location lane details
   */
  getLaneDetail: ({ locationId, laneId }: { locationId: number; laneId: number }) =>
    apiFetch(`/admin/site/${locationId}/lane/${laneId}`),

  /**
   * Get location Gate details
   */
  getGateDetail: ({ locationId, laneId }: { locationId: number; laneId: number }) =>
    apiFetch(`/admin/sites/${locationId}/lanes/${laneId}/gates`),

  /**
   * Create a new lane
   */
  updateLane: ({
    locationId,
    laneId,
    body,
  }: {
    locationId: number;
    laneId: number;
    body: {
      name: string;
      direction: string;
    };
  }) =>
    apiFetch(`/admin/site/${locationId}/lane/${laneId}`, {
      method: 'POST',
      body,
    }),

  addLaneCamera: ({
    locationId,
    laneId,
    body: { approachSettingType, ...body },
  }: {
    locationId: number;
    laneId: number;
    body: AddLaneCameraPayload;
  }) =>
    apiFetch(`/admin/location/${locationId}/equipment`, {
      method: 'POST',
      body: {
        ...body,
        laneId,
        config: {
          ...defaultCameraConfig,
          approachSettingType,
        },
      },
    }),

  updateLaneCamera: ({
    locationId,
    laneId,
    body: { approachSettingType, ...body },
  }: {
    locationId: number;
    laneId: number;
    body: UpdateLaneCameraPayload;
  }) =>
    apiFetch(`/admin/location/${locationId}/equipment/${body.id}`, {
      method: 'PUT',
      body: {
        ...body,
        laneId,
        approachSettingType,
        config: {
          ...defaultCameraConfig,
          approachSettingType,
        },
      },
    }),

  removeLaneCamera: ({
    locationId,
    laneId,
    cameraId,
  }: {
    locationId: number;
    laneId: number;
    cameraId: number;
  }) => apiFetch(`/admin/location/${locationId}/equipment/${cameraId}`, { method: 'DELETE' }),

  getDoors: ({ locationId }: { locationId: number }) => apiFetch(`/admin/site/${locationId}/doors`),

  openDoor: ({ locationId, doorId }: { locationId: number; doorId: number }) =>
    apiFetch(`/admin/site/${locationId}/doors/${doorId}/open`, { method: 'POST' }),

  createDoor: ({ locationId, body }: { locationId: number; body: PedestrianDoorUpsertPayload }) =>
    apiFetch(`/admin/site/${locationId}/doors`, {
      method: 'PUT',
      body: [body],
    }),

  getLaneDevices: ({ locationId }: { locationId: number }) =>
    apiFetch(`/admin/sites/${locationId}/devices`, {
      method: 'GET',
    }),

  adoptLaneDevice: ({
    siteId,
    deviceUuid,
    body,
  }: {
    siteId: number;
    deviceUuid: string;
    body: BigMacDevice;
  }) =>
    apiFetch(`/admin/sites/${siteId}/devices/${deviceUuid}`, {
      method: 'PUT',
      body,
    }),

  updateLaneDevice: ({ body }: { body: BigMacDevice }) =>
    apiFetch(`/ehcs/devices/${body.uuid}/update`, {
      method: 'PUT',
      body,
    }),

  abandonLaneDevice: ({ siteId, deviceId }: { siteId: number; deviceId: string }) =>
    apiFetch(`/admin/sites/${siteId}/devices/${deviceId}`, {
      method: 'DELETE',
    }),

  addGate: ({
    payload,
    siteId,
    laneId,
  }: {
    siteId: number;
    laneId: number;
    payload: {
      deviceUuid: string;
      deviceRelayNum: number;
      notes: string;
      laneId: number;
      enabled: boolean;
      gateActions: {
        automatic: string;
        subscriber: string;
        textToPay: string;
        nestedZone: string;
        textToVend: string;
        valetexit: string;
      };
    };
  }) =>
    apiFetch(`/admin/sites/${siteId}/lanes/${laneId}/gates`, {
      method: 'POST',
      body: payload,
    }),

  updateGate: ({
    payload,
    siteId,
    laneId,
    gateId,
  }: {
    siteId: number;
    laneId: number;
    gateId: number;
    payload: {
      deviceUuid: string;
      deviceRelayNum: number;
      notes: string;
      laneId: number;
      enabled: boolean;
      gateActions: {
        automatic: string;
        subscriber: string;
        textToPay: string;
        nestedZone: string;
        textToVend: string;
        valetexit: string;
      };
    };
  }) =>
    apiFetch(`/admin/sites/${siteId}/lanes/${laneId}/gates/${gateId}`, {
      method: 'PUT',
      body: payload,
    }),

  removeGate: ({ siteId, laneId, gateId }: { siteId: number; laneId: number; gateId: number }) =>
    apiFetch(`/admin/sites/${siteId}/lanes/${laneId}/gates/${gateId}`, {
      method: 'DELETE',
    }),
};
